import type { CurrencyCode } from '../types/UserPreferences';

export const DEFAULT_CURRENCY_CODE = 'EUR';

const countryCurrencyCodes = {
  'GBP': 'GB',
  'USD': 'US',
  'EUR': 'NL',
};

export const getCountryByCurrency = (currency: CurrencyCode) => {
  return countryCurrencyCodes[currency];
};
