import dateFormat from 'dateformat';

import { parseDate } from '../../../utils/datetime';

export const formatDate = (date: string | Date) => {
  const d =
    typeof date === 'string' ? new Date(toClientUTCDateFormat(date)) : date;
  return dateFormat(d, 'mmmm dd, yyyy');
};

export const formatUpcomingLaunchDate = (launchDate: string) => {
  return `${dateFormat(
    new Date(toClientUTCDateFormat(launchDate)),
    'GMT:dddd dd, mmmm – HH:MM',
  )} GMT`;
};

export const formatLaunchDate = (launchDate: string | Date) => {
  return `${dateFormat(
    launchDate instanceof Date
      ? launchDate
      : new Date(toClientUTCDateFormat(launchDate)),
    'GMT:mmmm dd, yyyy – HH:MM',
  )} GMT`;
};

export const isLaunchDateUpcoming = (launchDate: string) => {
  const parsedDate = parseDate(launchDate);

  if (parsedDate === null) {
    // If there's a parsing error we should still let users subscribe.
    return true;
  }

  return new Date() < parsedDate;
};

export const isLaunchDateInPast = (launchDate: string) => {
  const parsedDate = parseDate(launchDate);

  if (parsedDate === null) {
    // If there's a parsing error, we shouldn't let users purchase.
    return false;
  }

  return new Date() > parsedDate;
};

export const toClientUTCDateFormat = (dateString: string) => {
  return String(`${dateString}Z`).replace(' ', 'T');
};
