import { TFunction } from 'next-i18next';

import { storyblokApolloClient } from '../apollo/storyblok/client';
import { StoryblokArtistsGateway } from '../gateways/StoryblokArtistsGateway';
import {
  ArtistSearchResult,
  AugmentedArtistCard,
  WorkType,
} from '../types/Artists';
import { DEFAULT_CURRENCY_CODE } from './currency';
import { fetchAllProducts } from './shopify/fetchAllProducts';
import shuffleArray from './shuffleArray';
import { normaliseString } from './strings';

const artistsGateway = new StoryblokArtistsGateway(storyblokApolloClient);

export async function getArtistsCount() {
  const artists = await artistsGateway.allArtists();
  return artists.length;
}

// Adds properties about artists works to their cards
export async function augmentArtists(
  artists: ArtistCard[],
  internalArtists?: ArtistSearchResult[],
): Promise<AugmentedArtistCard[]> {
  if (artists.length === 0) {
    return Promise.resolve([]);
  }

  const products = await fetchAllProducts(
    'tag:Listing page',
    DEFAULT_CURRENCY_CODE,
    false,
  );

  return artists.map((artist) => {
    const internalArtist =
      internalArtists &&
      internalArtists.find(
        (intArtist) => intArtist.slug === artist.link.split('/').pop(),
      );

    const works = products.filter((product) => {
      const artistName = (product.node as Record<string, unknown>)
        .artistName as ShopifyMetafield;

      return (
        normaliseString(artistName?.value) === normaliseString(artist.name)
      );
    });

    const workTypes: AugmentedArtistCard['workTypes'] = [];

    if (anyWorkHasTag(works, 'Print edition')) workTypes.push('PRINT');
    if (anyWorkHasTag(works, 'Sculpture edition')) workTypes.push('SCULPTURE');
    if (anyWorkHasTag(works, 'Studio works')) workTypes.push('STUDIO WORKS');
    if (anyWorkHasTag(works, 'Collectible')) workTypes.push('COLLECTIBLE');
    if (anyWorkHasTag(works, 'NFTs')) workTypes.push('NFT');

    const hasAvailableWorks = works.some((w) =>
      w.node.tags.some((t) => t.toLowerCase() === 'available'),
    );

    const hasPlannedReleases =
      artist.plannedReleases && artist.plannedReleases.length > 0;

    const plannedReleases = artist.plannedReleases
      ? artist.plannedReleases.flatMap((pr) =>
          pr.releaseType.map((r) => ({
            availability: 'upcoming',
            type: r.toUpperCase() as WorkType,
          })),
        )
      : [];

    const hasUpcomingWorksRelease = works.some((w) =>
      w.node.tags.some((t) => t.toLowerCase() === 'coming soon'),
    );

    const workTypesMap = {
      'print edition': 'PRINT' as WorkType,
      'sculpture edition': 'SCULPTURE' as WorkType,
      'studio works': 'STUDIO WORKS' as WorkType,
      'collectible': 'COLLECTIBLE' as WorkType,
      'nfts': 'NFT' as WorkType,
    };

    const availabilityMap = {
      'available': 'available',
      'coming soon': 'upcoming',
      'sold out': 'sold out',
    };

    const artistWorks = works
      .map((w) => {
        return {
          type: w.node.tags.find((t) =>
            Object.keys(workTypesMap).includes(t.toLowerCase()),
          ),
          available: w.node.tags.find((t) =>
            Object.keys(availabilityMap).includes(t.toLowerCase()),
          ),
        };
      })
      .filter((aw) => aw.type && aw.available)
      .map((aw) => ({
        type: workTypesMap[aw.type!.toLowerCase() as keyof typeof workTypesMap],
        availability:
          availabilityMap[
            aw.available!.toLowerCase() as keyof typeof availabilityMap
          ],
      }));

    return {
      ...artist,
      plannedReleases:
        artist.plannedReleases && artist.plannedReleases.length > 0
          ? artist.plannedReleases
          : null,
      totalWorks: works.length,
      workTypes,
      hasUpcomingRelease: hasUpcomingWorksRelease || hasPlannedReleases!,
      hasAvailableWorks,
      works: [...artistWorks, ...plannedReleases],
      internalId: internalArtist && internalArtist.id ? internalArtist.id : '',
    };
  });
}

export async function getRelatedArtists(
  excludedName?: string,
): Promise<AugmentedArtistCard[]> {
  const artists = await artistsGateway.allArtists();
  const augmentedArtists = await augmentArtists(artists);

  const includedArtists = augmentedArtists.filter(
    (artist) => artist.name !== excludedName && !artist.excludeFromIndex,
  );

  const artistsWithUpcomingProducts = shuffleArray(
    includedArtists.filter((a) => a.hasUpcomingRelease),
  ).slice(0, 3);

  const otherArtists = shuffleArray(
    includedArtists.filter((artist) =>
      artistsWithUpcomingProducts.every((a) => a.name !== artist.name),
    ),
  ).slice(0, 6 - artistsWithUpcomingProducts.length);

  return shuffleArray([...artistsWithUpcomingProducts, ...otherArtists]);
}

function anyWorkHasTag(works: ShopifyProductObject[], tag: string): boolean {
  return works.some((w) => {
    return w.node.tags.some((t) => t.toLowerCase() === tag.toLowerCase());
  });
}

export const getArtistTileOverline = (
  totalWorks: number,
  hideCollaborations: boolean | null,
  t: TFunction,
) => {
  if (hideCollaborations === true) {
    return '-';
  }

  if (totalWorks === 0) {
    return t('artists:artistCollaborationUpcoming');
  }

  return totalWorks === 1
    ? `${totalWorks} ${t('artists:artistCollaborationSingle')}`
    : `${totalWorks} ${t('artists:artistCollaborationPlural')}`;
};

export const mapArtistPageContentToArtist = (
  artistPageContent: ArtistPageContent,
): ArtistCard => ({
  uuid: artistPageContent?.uuid || '',
  alt: artistPageContent.content?.thumbnail?.alt || '',
  focus: artistPageContent.content?.thumbnail?.focus || '',
  image: artistPageContent.content?.thumbnail?.filename || '',
  hideCollaborations: artistPageContent.content?.hideCollaborations || null,
  link: artistPageContent?.full_slug || '',
  name: artistPageContent.content?.name || '',
  overline: '',
  createdAt: artistPageContent.createdAt || '',
  firstPublishedAt: artistPageContent.first_published_at || '',
  galleryImages: [],
  slug: artistPageContent.slug,
  plannedReleases: artistPageContent.content?.plannedReleases
    ? artistPageContent.content.plannedReleases
    : null,
});

export function renderShopifyArtistName(artistName: string) {
  return artistName
    .split(',')
    .map((s) => s.trim())
    .join(' x ');
}

export function renderAugmentedArtistsNames(
  augmentedArtists: Array<{ name: string }>,
) {
  return augmentedArtists.map((aa) => aa.name).join(' x ');
}

export function renderArtistsNamesFromPageContent(
  relatedArtists: RelatedArtist[] | undefined | null,
) {
  if (!relatedArtists) return '';

  return relatedArtists
    .map((ra: ResolvedRelatedArtist) => ra.artist)
    .filter(Boolean)
    .map((a) => a.name)
    .join(' x ');
}
